import Vue from 'vue'
import vueRouter from 'vue-router'

Vue.use(vueRouter)

export default new vueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        {path:'', name:'collection', component: resolve=>(require(["@/view/home.vue"],resolve))},
        {path:'/list', name:'list', component: resolve=>(require(["@/view/list/list.vue"],resolve))},
        {path:'/detail', name:'detail', component: resolve=>(require(["@/view/list/detail.vue"],resolve))},
        {path:'/picPage', name:'picPage', component: resolve=>(require(["@/view/list/picPage.vue"],resolve))},
    ]
})