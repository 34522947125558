import Vue from 'vue';
import App from './App';

import { Pagination, Breadcrumb, Empty, Select } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Pagination);
Vue.use(Breadcrumb);
Vue.use(Empty);
Vue.use(Select);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueRouter from 'vue-router'
import router from './router/router'
Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
const thePageUrl = 'https://gmww.tjbwg.com/tbboot/'//'http://60.30.78.7:8082/tbboot/';//'http://192.168.80.72:8082/tbboot/';//
Vue.prototype.thePageUrl = thePageUrl;
import axios from 'axios'
let instance = axios.create({
  headers: {
      'content-type': 'application/json'
  }
})
instance.interceptors.request.use(
  config => {
      config.url = thePageUrl + config.url;
      if (config.url.indexOf('sys/login') == -1 || config.url.indexOf('pservice/') == -1) {
        config.headers['X-Access-Token'] = window.sessionStorage.getItem('token');
      }
      return config
  },
  err => {
    return Promise.reject(err)
  }
)
instance.interceptors.response.use(
  response => {
    console.log()
    if (response.status == 200) {
      return response.data;
    } else {
      ElementUI.Message.error('请求失败：' + response.status)
      return '请求失败：' + response.status;
    }
  },
  error => {
    window.location.href = '#/';
    console.log(window.location.href);
    window.sessionStorage.removeItem('token');
    return Promise.reject(JSON.stringify(error));
  }
)
Vue.prototype.$axios = instance;

Vue.config.productionTip = false;

new Vue({
  render: h => h(App), router
}).$mount('#app');